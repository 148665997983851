<!--15分钟每日葡萄糖概括图-->
<template>
  <div>
    <el-row :gutter="30" style="margin-left: 9%;width: 150%;">
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[0] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[1] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[2] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[3] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[4] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[5] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[6] }}</div></el-col>
</el-row>
    <div id="myChart2" style="width: 100%; height: 400px;margin-top: -50px;"></div>
    <!-- <div> <echart3 style="height: 20%;width:80% ;margin-left: 12%;"/>  </div>   -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
//import echart3 from '../components/echart3.vue'
export default {
  data() {
    return {
      nextWeekDays: [],
      generatedArray288: [],
      generatedArray2881: [],
      generatedArray70: [],
      generatedArray701: [],
      generatedArray70panduan: [],
      generatedArray70panduan1: [],
      generatedArray180: [],
      generatedArray1801: [],
      generatedArray110: [],
      generatedArray1101: [],
      generatedArray70cha: [],
      generatedArray70cha1: [],
      anomalyArray:[],
      anomalyArray1:[],
      GM: [],
      GM1: [],
      GM2: [],
      GMcha:[],
      GM2cha:[],
      GMNEXT: [],
      TWOid: '',
      startday: '',
      date1: 2015,
      date2: 4031,

      date3: 735,
      date4: 1008,

      starttime: '',
      cha: '',
    };
  },
  props: [
    'userid',
    'startday',
    'minute',
  ],
  components: {

  },
  created() {

    this.getMidCompute1();

  },

  mounted() {

    this.getMidCompute1();

    this.getweeks();
  },
  methods: {
    getweeks() {
      const startDate = new Date(this.startday);
      const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六']
      if (!isNaN(startDate.getTime())) {
        // 初始化一个数组，用于存储一星期内每天的星期值


        // 循环计算一星期内每天的星期值
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + i);
          this.nextWeekDays.push(daysOfWeek[currentDate.getDay()]);
        }
      } else {
        this.nextWeekDays = ['无效日期'];
      }
      // console.log(this.nextWeekDays)
    },

    ADD1() {
      this.processArray21();
      this.processArray1();
      this.drawGraph1(this.generatedArray288, this.generatedArray70panduan, this.GM, this.GM2, this.generatedArray110, this.generatedArray180, this.generatedArray70cha,
          this.generatedArray2881, this.generatedArray70panduan1, this.generatedArray1101, this.generatedArray1801, this.generatedArray70cha1,this.GMcha,this.GM2cha)
      // console.log(this.generatedArray70cha)

    },

    //96//
    getMidCompute1() {
      axios.get('http://js.aps.ink:8090/selectbgFromOneDay', {
        // 传递的参数
        params: {
          id: this.userid,
          startday: this.startday,
        }
        // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
      }).then(res => {
        // 如果状态为400,说明登录失败
        if (res.data.status === 400) {
          // 登录失败提示

        } else {
          // 登录成功提示
          this.GM1 = res.data.mgList
          this.starttime = res.data.timeList[0];
          const hour = this.starttime.substring(11, 13)
          const fen = this.starttime.substring(14, 16)
          const move1 = parseInt(hour, 10)
          const move2 = parseInt(fen, 10)
          // console.log(move1);
          // console.log(fen);
          var move = Math.round(((move1 * 60) + move2) / 5);
          // console.log(move);
          this.cha = this.date3 - move;
          for (let i = 0; i < move; i++) {
            this.GM[i] = 0;
          }
          ;
          for (let i = move; i < this.date3; i++) {
            this.GM[i] = this.GM1[i - move];
          }
          ;
          const index = this.date3 - move
          this.GM2 = this.GM1.slice(index, this.GM1.length);
          // console.log(this.GM2);
          for (let i = this.GM1.length; i < this.date4; i++) {
            this.GM2[i] = 70;
          }
          // 进行插值操作
          this.GMcha = this.interpolateZeros1(this.GM, 6); // 对 GM 进行插值操作
          this.GM2cha = this.interpolateZeros1(this.GM2, 6); // 对 GM2 进行插值操作

          this.ADD1()
        }
      }).catch(error => {
        console.log(error)
      })
    },

    interpolateZeros1(arr, threshold) {
      let zeroStart = -1; // 连续0的起始位置
      let zeroCount = 0;  // 连续0的计数
      let resultArray = new Array(arr.length).fill(0); // 创建新数组，初始为全0

      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 0) {
          if (zeroStart === -1) zeroStart = i; // 开始记录连续0的起始位置
          zeroCount++;
        } else {
          if (zeroCount > 0 && zeroCount <= threshold) {
            // 插值操作
            const startValue = arr[zeroStart - 1] || 0; // 前一个有效值
            const endValue = arr[i]; // 后一个有效值

            // 线性插值计算
            for (let j = 0; j < zeroCount; j++) {
              arr[zeroStart + j] = startValue + (endValue - startValue) * ((j + 1) / (zeroCount + 1));
              resultArray[zeroStart + j] = 35; // 固定插值为35
            }
          }
          zeroStart = -1; // 重置起始位置
          zeroCount = 0;  // 重置计数
        }
      }

      // 如果数组末尾有连续0
      if (zeroCount > 0 && zeroCount <= threshold) {
        const startValue = arr[zeroStart - 1] || 0; // 前一个有效值
        const endValue = arr[arr.length - 1] || 0; // 假设最后一个有效值为0
        for (let j = 0; j < zeroCount; j++) {
          arr[zeroStart + j] = startValue + (endValue - startValue) * ((j + 1) / (zeroCount + 1));
          resultArray[zeroStart + j] = 35; // 固定插值为35
        }
      }

      return resultArray;
    },
    processArray21() {
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] === 0) {
          this.generatedArray1101[i] = 180;
        }
        else {
          this.generatedArray1101[i] = 110;
        }

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        this.generatedArray2881[i] = "";

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70panduan1[i] = 70
        } else {
          this.generatedArray70panduan1[i] = this.GM2[i]

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] < 180) {
          this.generatedArray1801[i] = 0
        } else {
          this.generatedArray1801[i] = this.GM2[i] - 180

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70cha1[i] = 0
        } else if(this.GM2[i] === 0 ) {
          this.anomalyArray1[i]= 70
        }
        else{
          this.generatedArray70cha1[i] = 70 - this.GM2[i]
        }
      }
      ;
    },



    processArray1() {
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] === 0) {
          this.generatedArray110[i] = 180;
        }
        else {
          this.generatedArray110[i] = 110;
        }

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        this.generatedArray288[i] = "";

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70panduan[i] = 70
        } else {
          this.generatedArray70panduan[i] = this.GM[i]

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] < 180) {
          this.generatedArray180[i] = 0
        } else {
          this.generatedArray180[i] = this.GM[i] - 180

        }
      }
      ;
      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70cha[i] = 0
        } else if(this.GM[i] === 0){
          this.anomalyArray[i] = 70
        }
        else {
          this.generatedArray70cha[i] = 70 - this.GM[i]
        }
      }

    },


    drawGraph1(generatedArray288, generatedArray70panduan, GM, GM2, generatedArray110, generatedArray180, generatedArray70cha,
               generatedArray2881, generatedArray70panduan1, generatedArray1101, generatedArray1801, generatedArray70cha1,GMcha,GM2cha
    ) {
      const myChart = echarts.init(document.getElementById('myChart2'));
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        toolbox: {
          feature: {}
        },
        grid: [
          {
            left: '3%',
            right: '4%',
            top: '3%',
            bottom: '50%',  // 调整第一个网格的 bottom 属性
            containLabel: true
          },
          {
            left: '3%',
            right: '4%',
            top: '50%',  // 调整第二个网格的 top 属性
            bottom: '3%',  // 调整第二个网格的 bottom 属性
            containLabel: true
          },

        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray288,
            gridIndex: 0,
            zlevel: 1, // 提高此轴及其相关元素的图层等级
            axisLabel: {
              interval: Math.ceil(generatedArray288.length / 14) - 1,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#212020',
                width: 1,
              },
              interval: Math.floor(generatedArray288.length / 7),
            }
          },
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray2881,
            gridIndex: 1,
            zlevel: 1, // 同样提高第二个轴的图层等级
            axisLabel: {
              interval: Math.ceil(generatedArray2881.length / 14) - 1,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#212020',
                width: 1,
              },
              interval: Math.floor(generatedArray2881.length / 7),
            }
          }
        ],
        yAxis: [
          {
            name: "毫克/分钟",
            type: 'value',
            gridIndex: 0

          },
          {
            type: 'value',
            gridIndex: 1

          },

        ],
        series: [
          {
            type: 'scatter', // 使用散点图类型
            symbolSize:5,
            itemStyle: {
              color: 'red', // 红色圆圈
            },
            z:100,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0,// 指定使用第二个y轴
            data: GMcha.map(value => value === 0 ? null : value), // 散点图数据
          },
          {
            type: 'scatter', // 使用散点图类型
            symbolSize:5,
            itemStyle: {
              color: 'red', // 红色圆圈
            },
            z:100,
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1,// 指定使用第二个y轴
            data: GM2cha.map(value => value === 0 ? null : value), // 散点图数据
          },
          {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: 'rgb(208,243,199)' // 设置边界线的颜色为透明
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha,
            xAxisIndex: 0,
            yAxisIndex: 0
          },

          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)',
              origin: 70
            },
            lineStyle: {
              color: 'rgb(208,243,199)', // 设置边界线的颜色，例如绿色
              width: 2               // 设置边界线的宽度
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray110.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            type: 'line',
            smooth: true,
            stack: 'Total',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray180.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM.map(value => value !== 0 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: 'none',           // 无标记
              label: { show: false },   // 不显示标记线的标签
              lineStyle: {
                color: 'rgb(208,0,255)',         // 线条颜色为红色
                width: 5,             // 线条宽度
                type: 'solid'         // 线条类型为实线
              },
              data: (() => {
                const acc = [];
                let start = null;

                GM.forEach((value, index) => {
                  if (value === 0 && start === null) {
                    start = index;  // 记录开始点
                  } else if (value !== 0 && start !== null) {
                    acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: index - 1, yAxis: 0 }]);
                    start = null;  // 重置开始点
                  }
                });

                // 如果最后的数据也是0，需要补充最后一段线
                if (start !== null) {
                  acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: GM.length - 1, yAxis: 0 }]);
                }

                return acc;
              })()
            },
            xAxisIndex: 0,  // 使用第一个 x 轴
            yAxisIndex: 0   // 使用第一个 y 轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan1.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: 'rgb(208,243,199)' // 设置边界线的颜色为透明
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha1,
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)',//2背景
              origin: 70
            },
            lineStyle: {
              color: 'rgb(208,243,199)', // 设置边界线的颜色，例如绿色
              width: 2               // 设置边界线的宽度
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1101.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            smooth: true,
            stack: 'Total1',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1801.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM2.map(value => value !== 0 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: 'none',           // 无标记
              label: { show: false },   // 不显示标记线的标签
              lineStyle: {
                color: 'rgb(208,0,255)',         // 线条颜色为红色
                width: 5,             // 线条宽度
                type: 'solid'         // 线条类型为实线
              },
              data: (() => {
                const acc = [];
                let start = null;

                GM2.forEach((value, index) => {
                  if (value === 0 && start === null) {
                    start = index;  // 记录开始点
                  } else if (value !== 0 && start !== null) {
                    acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: index - 1, yAxis: 0 }]);
                    start = null;  // 重置开始点
                  }
                });

                // 如果最后的数据也是0，需要补充最后一段线
                if (start !== null) {
                  acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: GM.length - 1, yAxis: 0 }]);
                }

                return acc;
              })()
            },
            xAxisIndex: 1,  // 使用第一个 x 轴
            yAxisIndex: 1   // 使用第一个 y 轴
          }


        ]
      }
      myChart.setOption(option);
    }
  }

}


</script>

<style scoped>
#myChart {
  width: 10%;
}

.xingqi1 {
  margin-left: 150px;
  margin-top: -200px;


}

.xingqi2 {
  margin-left: 350px;



}

.xingqi3 {
  margin-left: 550px;


}

.xingqi4 {
  margin-left: 750px;


}

.xingqi5 {
  margin-left: 950px;


}

.xingqi6 {
  margin-left: 1150px;


}

.xingqi7 {
  margin-left: 1350px;


}



.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #ffffff;
  }
  .bg-purple {
    background: transparent;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>