<!--5分钟AGP图谱-->
<template>
    <div>
<!--      <div class="value250">     250</div>-->
<!--      <div class="value180">     180</div>-->
      <div id="myChart" style="width: 100%; height: 400px;"></div>

    </div>
  </template>
    
  <script>
 import * as echarts from 'echarts';
  import axios from 'axios';
  
  export default {
    data() {
      return {

        generatedArray288: [],
        generatedArray70: [],
        generatedArray54: [],
        generatedArray180: [],
        generatedArray250: [],
        generatedArray54panduan: [],
        generatedArray54panduan1: [],


        TWOid: '',
        TWOPer95:[],
        TWOPer75:[],
        TWOPer50:[],
        TWOPer25:[],
        TWOPer5:[],
 
        date1:288,
        date2:96,
        starttime:'',
      };
    },
    props: [
      'userid',
      'startday',
      'minute',
    ],

    created() {

        this.getMidCompute288();

    },
    mounted() {

        this.getMidCompute288();

    },
    methods: {

      ADD288() {
        this.processArray288();
        this.drawGraph288(this.generatedArray288,this.TWOPer95,this.TWOPer75,this.TWOPer50,this.TWOPer25,this.TWOPer5,this.generatedArray54panduan,this.generatedArray54panduan1,this.generatedArray70,this.generatedArray54,this.generatedArray180,this.generatedArray250
         )
      },

      getMidCompute288() {
                axios.get('http://js.aps.ink:8090/get288Mid', {
                  // 传递的参数
                  params: {
                    id: this.userid,
                    startday: this.startday
                  }
                  // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
                }).then(res => {
                  // 如果状态为400,说明登录失败
                  if (res.data.status === 400) {
                    // 登录失败提示

                  } else {
                    // 登录成功提示
                    this.TWOPer95 = res.data.per95
                    this.TWOPer75 = res.data.per75
                    this.TWOPer50 = res.data.per50
                    this.TWOPer25 = res.data.per25
                    this.TWOPer5 = res.data.per5
                    // console.log("per5",res.data.per5)
                    this.ADD288()
                  }
                }).catch(error => {
                  console.log(error)
                })

      },
      // //去掉头天缺失数据版
      // getMidCompute288_1() {
      //   axios.get('http://js.aps.ink:8090/get288Mid_1', {
      //     // 传递的参数
      //     params: {
      //       id: this.userid,
      //       startday: this.startday
      //     }
      //     // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
      //   }).then(res => {
      //     // 如果状态为400,说明登录失败
      //     if (res.data.status === 400) {
      //       // 登录失败提示
      //
      //     } else {
      //       // 登录成功提示
      //       this.TWOPer95 = res.data.per95
      //       this.TWOPer75 = res.data.per75
      //       this.TWOPer50 = res.data.per50
      //       this.TWOPer25 = res.data.per25
      //       this.TWOPer5 = res.data.per5
      //       // console.log("per5",res.data.per5)
      //       this.ADD288()
      //     }
      //   }).catch(error => {
      //     console.log(error)
      //   })
      //
      // },


      processArray288() {
        for (let i = 0; i < this.date1; i++) {
          this.generatedArray70[i] = 70;
  
        };
        for (let i = 0; i < this.date1; i++) {
          if (i/12 < 1) {
          this.generatedArray288[i] = "12am";
        }else if(i/12 >= 1 && i/12 < 12){
          this.generatedArray288[i] = parseInt(i/12)+"am";
        }else if(i/12 >= 12 && i/12 <= 13){
          this.generatedArray288[i] = 12+"pm";
        }
        else if(i/12 >= 13 && i/12 <= 24){
          this.generatedArray288[i] = parseInt(i/12)-12+"pm";
        }else{
          this.generatedArray288[i] = 12+"pm";
        }
      };
        
        for (let i = 0; i < this.date1; i++) 
        {
        if(this.TWOPer5[i]<54)
          {this.generatedArray54[i] = 54-this.TWOPer5[i]
        } else {
            this.generatedArray54[i] = 0
        }
        };
        // console.log(this.generatedArray54)
        for (let i = 0; i < this.date1; i++) {
          this.generatedArray180[i] = 180;
  
        };
        for (let i = 0; i < this.date1; i++) {
          this.generatedArray250[i] = 250;
  
        };


        for (let i = 0; i < this.date1; i++) 
        {
          if (this.TWOPer5[i] < 54) {
            this.generatedArray54panduan1[i] = this.TWOPer5[i];
          } else  {
            this.generatedArray54panduan1[i] = 54;
          } 
        };


        for (let i = 0; i < this.date1; i++) 
        {
          if (this.TWOPer5[i] <54 || this.TWOPer5[i]== 54) {
            this.generatedArray54panduan[i] = 0;
          } else if(54 <this.TWOPer5[i]&& this.TWOPer5[i]< 70) {
            this.generatedArray54panduan[i] = this.TWOPer5[i]-54
          } else if(this.TWOPer5[i]>70) 
        {
            this.generatedArray54panduan [i] = 16
        }
        };
        // console.log(this.generatedArray54panduan)
        
      },
    drawGraph288(generatedArray288,TWOPer95, TWOPer75, TWOPer50, TWOPer25, TWOPer5,generatedArray54panduan,generatedArray54panduan1,
      generatedArray70,generatedArray54,generatedArray180,generatedArray250) {
        const myChart = echarts.init(document.getElementById('myChart'));
  
        const option = {
                //backgroundColor: '#2c343c', 调节背景色，这里我注释掉了
                title:{
                    text:''
                },
                tooltip:{ trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }}},
                legend:{
                  show: false
                },
                
                xAxis:{
                    type:'category',        //坐标轴类型：类目轴
                    boundaryGap:false,      //1.基础面积图  ：类目轴中：留白区域，与左边坐标轴和右边图的边缘之间是否有留白。默认true
                    data:generatedArray288,
                    axisTick:{
                        show: true, //显示坐标轴刻度线
                        inside: true //显示刻度线朝内
                    }
                },
                yAxis:{
                     name: '   mg/dL(毫克/分升)',
                     type: 'value',
                },
                series:[
                {
                    name:'95%',
                    type:'line',
                    endLabel: {
                    show: true,
                    formatter: function (params) {
                      return ' ' ;
                    }
                          },   // 不显示曲线每个点的小圆点
                    lineStyle : {
                        color : 'BurlyWood',
                        width : 1,  //设置曲线宽度
                        type : 'solid',
                   },
                    data:TWOPer95,
                    symbol: 'none',
                    smooth:true,        //平滑曲线图。值可为数字
                    areaStyle:{         //2.基础面积图。区域填充样式
                        color:'BurlyWood',
                        origin:70,     //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }         
                },
                {
                    name:'50%',
                    type:'line',
                    endLabel: {
                    show: true,
                    formatter: function (params) {
                      return ' ' ;
                    }
                          },   // 不显示曲线每个点的小圆点
                    lineStyle : {
                        color : '#C82423',
                        width : 2,  //设置曲线宽度
                        type : 'solid',
                },
                    data:TWOPer50,
                    smooth:true,        //平滑曲线图。值可为数字
                    symbol: 'none',
                },
                


                {
                    name:'75%',
                    type:'line',
                    lineStyle : {
                        color : 'DarkGoldenrod',
                        width : 1,
                        type : 'solid',
                },
                    data:TWOPer75,
                    symbol: 'none',
                    endLabel: {
                    show: true,
                    formatter: function (params) {
                      return ' ' ;
                    }
                          }, 
                    smooth:true,        //平滑曲线图。值可为数字
                    areaStyle:{         //2.基础面积图。区域填充样式
                        color:'DarkGoldenrod',
                        origin:70,      //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }     
                },
                {
                    name:' ',
                    type:'line',
                    lineStyle : {
                        color : 'Aquamarine',
                        width : 1,
                        type : 'solid',
                },
                    data:TWOPer25,
                    smooth:true,
                    symbol: 'none',
                    endLabel: {
                    show: true,
                    formatter: function (params) {
                      return ' ' ;
                    }
                          },     //平滑曲线图。值可为数字
                    areaStyle:{         //2.基础面积图。区域填充样式
                        color:'Aquamarine',
                        origin:54,      //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }        
                    ,zlevel: 1   
                },
                {
                    name:'5%',
                    type:'line',
                    endLabel: {
                    show: true,
                    formatter: function (params) {
                      return ' ' ;
                    }
                          }, 
                    lineStyle : {
                        color : 'rgb(255, 255, 255)',
                        width : 1,
                        type : 'solid',
                },
                    data:TWOPer5,
                    symbol: 'none',
                    smooth:true,        //平滑曲线图。值可为数字
                    areaStyle:{         //2.基础面积图。区域填充样式
                        color:'rgb(255, 255, 255)' ,
                         //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }     ,
                    zlevel: 2   
                },
                {
                    name:'',
                    type:'line',
                    symbol: 'none',
                    lineStyle : {
                        color : 'Black',
                        width : 1,
                        type : 'solid',
                },
                    data:generatedArray70,
                    smooth:true,        //平滑曲线图。值可为数字
                    areaStyle:{         //2.基础面积图。区域填充样式
                        color:'Brown',
                        opacity:0.3, 
                        origin:54,  //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }        
                },
                  {
                    name: '',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: {
                      color: 'transparent', // 使线条颜色透明
                      width: 1,
                      type: 'solid',
                    },
                    data: generatedArray180,
                    smooth: true, // 平滑曲线图
                    areaStyle: { // 基础面积图区域填充样式
                      color: 'ForestGreen',
                      opacity: 0.1,
                      origin: 70, // 支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    },
                    zlevel: 2
                  },
                  {
                    name: '',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: {
                      color: 'transparent', // 使线条颜色透明
                      width: 1,
                      type: 'solid',
                    },
                    data: generatedArray250,
                    smooth: true, // 平滑曲线图
                    areaStyle: { // 基础面积图区域填充样式
                      color: 'transparent',
                      origin: 200, // 支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
                    }
                  },

                  {
                type: 'line',
                stack: 'Total2',
                smooth: true,
                symbol: 'none',
                areaStyle: {
                  opacity: 0.8,
                  color:'rgb(255, 255, 255)'
                },
                emphasis: {
                  focus: 'series'
                },
                data: generatedArray54panduan1,
              },
      
      {
        type: 'line',
        stack: 'Total2',
        smooth: true,
        symbol: 'none',
        areaStyle: {opacity: 0.8,
          color:'rgb(255, 0, 0)'},
        emphasis: {
          focus: 'series'
        },
        data:  generatedArray54
      },
      {
        type: 'line',
        stack: 'Total2',
        smooth: false,
        symbol: 'none',
        areaStyle: {opacity: 0.8,
          color:'rgb(255, 255, 255)'},
        emphasis: {
          focus: 'series'
        },
        data:generatedArray54panduan
      },
              ]
            } 
        myChart.clear();
        myChart.setOption(option,true);
      }
    },
      drawGraph96(generatedArray288,TWOPer95, TWOPer75, TWOPer50, TWOPer25, TWOPer5,generatedArray54panduan,generatedArray54panduan1,
                 generatedArray70,generatedArray54,generatedArray180,generatedArray250) {
      const myChart = echarts.init(document.getElementById('myChart'));

      const option = {
        //backgroundColor: '#2c343c', 调节背景色，这里我注释掉了
        title:{
          text:''
        },
        tooltip:{ trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }}},
        legend:{
          show: false
        },

        xAxis:{
          type:'category',        //坐标轴类型：类目轴
          boundaryGap:false,      //1.基础面积图  ：类目轴中：留白区域，与左边坐标轴和右边图的边缘之间是否有留白。默认true
          data:generatedArray288,
          axisTick:{
            show: true, //显示坐标轴刻度线
            inside: true //显示刻度线朝内
          }
        },
        yAxis:{
          name: '毫克/分钟',
          type: 'value',
        },
        series:[
          {
            name: 'Beijing AQI',
            type: 'line',
            symbol:'roundRect',
            markLine: {
              silent: true,

              lineStyle: {
                color: '#333'
              },
              data: [
                {
                  yAxis: 54
                },
                {
                  yAxis: 70
                },
                {
                  yAxis: 180
                },
              ]
            }
          }
          ,
          {
            name:'95%',
            type:'line',
            endLabel: {
              show: true,
              formatter: function (params) {
                return '95%' ;
              }
            },   // 不显示曲线每个点的小圆点
            lineStyle : {
              color : 'BurlyWood',
              width : 1,  //设置曲线宽度
              type : 'solid',
            },
            data:TWOPer95,
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'BurlyWood',
              origin:70,     //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }
          },
          {
            name:'50%',
            type:'line',
            endLabel: {
              show: true,
              formatter: function (params) {
                return '50%' ;
              }
            },   // 不显示曲线每个点的小圆点
            lineStyle : {
              color : '#C82423',
              width : 2,  //设置曲线宽度
              type : 'solid',
            },
            data:TWOPer50,
            smooth:true,        //平滑曲线图。值可为数字

          },



          {
            name:'75%',
            type:'line',
            lineStyle : {
              color : 'DarkGoldenrod',
              width : 1,
              type : 'solid',
            },
            data:TWOPer75,
            endLabel: {
              show: true,
              formatter: function (params) {
                return '75%' ;
              }
            },
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'DarkGoldenrod',
              origin:70,      //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }
          },
          {
            name:'25%',
            type:'line',
            lineStyle : {
              color : 'Aquamarine',
              width : 1,
              type : 'solid',
            },
            data:TWOPer25,
            smooth:true,
            endLabel: {
              show: true,
              formatter: function (params) {
                return '25%' ;
              }
            },     //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'Aquamarine',
              origin:54,      //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }
            ,zlevel: 1
          },
          {
            name:'5%',
            type:'line',
            endLabel: {
              show: true,
              formatter: function (params) {
                return '5%' ;
              }
            },
            lineStyle : {
              color : 'rgb(255, 255, 255)',
              width : 1,
              type : 'solid',
            },
            data:TWOPer5,
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'rgb(255, 255, 255)' ,
              //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }     ,
            zlevel: 2
          },
          {
            name:'',
            type:'line',
            symbol: 'none',
            lineStyle : {
              color : 'Black',
              width : 1,
              type : 'solid',
            },
            data:generatedArray70,
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'Brown',
              opacity:0.3,
              origin:54,  //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }
          },
          {
            name:'',
            type:'line',
            symbol: 'none',
            lineStyle : {
              color : 'Black',
              width : 1,
              type : 'solid',
            },
            data:generatedArray180,
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'ForestGreen',
              opacity:0.1 ,
              origin:70, //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            } ,zlevel: 2
          },
          {
            name:'',
            type:'line',
            symbol: 'none',
            lineStyle : {
              color : 'Black',
              width : 1,
              type : 'solid',
            },
            data:generatedArray250,
            smooth:true,        //平滑曲线图。值可为数字
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'Moccasin',
              origin:180,   //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            }
          },
          {
            type: 'line',
            stack: 'Total2',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color:'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray54panduan1,
          },

          {
            type: 'line',
            stack: 'Total2',
            smooth: true,
            symbol: 'none',
            areaStyle: {opacity: 0.8,
              color:'rgb(255, 0, 0)'},
            emphasis: {
              focus: 'series'
            },
            data:  generatedArray54
          },
          {
            type: 'line',
            stack: 'Total2',
            smooth: false,
            symbol: 'none',
            areaStyle: {opacity: 0.8,
              color:'rgb(255, 255, 255)'},
            emphasis: {
              focus: 'series'
            },
            data:generatedArray54panduan
          },
        ]
      }
      myChart.clear();
      myChart.setOption(option,true);
    }

  
  }
  
  
  </script>
    
  <style scoped>
  #myChart {
    width: 10%;
  }
  .value250 {
                        position: absolute;
                        margin-top: -260px;
                        margin-left: -70px;
                        /* 调整数值的垂直位置，使其在横线上方 */
                        left: 12%;
                        /* 将数值置于横线正中间 */
                     
                    }
                    .value180 {
                        position: absolute;
                        margin-top: -230px;
                        margin-left: -70px;
                        /* 调整数值的垂直位置，使其在横线上方 */
                        left: 12%;
                        /* 将数值置于横线正中间 */
                    
                    }
  .xingqi1 {
    margin-left: 150px;
  
  
  
  }
  
  .xingqi2 {
    margin-left: 350px;
  
  
  
  }
  
  .xingqi3 {
    margin-left: 550px;
  
  
  }
  
  .xingqi4 {
    margin-left: 750px;
  
  
  }
  
  .xingqi5 {
    margin-left: 950px;
  
  
  }
  
  .xingqi6 {
    margin-left: 1150px;
  
  
  }
  
  .xingqi7 {
    margin-left: 1350px;
  
  
  }</style>