<!--5分钟每日葡萄糖概括图-->
<template>
  <div>
    <el-row :gutter="30" style="margin-left: 9%;width: 135%;">
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[0] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[1] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[2] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[3] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[4] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[5] }}</div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="transform: translateY(-10px);">星期{{ nextWeekDays[6] }}</div>
      </el-col>

    </el-row>
    <div id="myChart2" style="width: 90%; height: 400px;margin-top: -60px;"></div>
    <!-- <div> <echart3 style="height: 20%;width:80% ;margin-left: 12%;"/>  </div>   -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
//import echart3 from '../components/echart3.vue'
export default {
  data() {
    return {
      nextWeekDays: [],
      generatedArray288: [],
      generatedArray2881: [],
      generatedArray70: [],
      generatedArray701: [],
      generatedArray70panduan: [],
      generatedArray70panduan1: [],
      generatedArray180: [],
      generatedArray1801: [],
      generatedArray110: [],
      generatedArray1101: [],
      generatedArray70cha: [],
      generatedArray70cha1: [],
      anomalyArray:[],
      anomalyArray1:[],
      GM: [],
      GM1: [],
      GM2: [],
      GMcha:[],
      GM2cha:[],
      GM_real: [],
      GM2_real: [],
      GMNEXT: [],
      TWOid: '',
      startday: '',
      date1: 2000,
      date2: 4031,


      starttime: '',
      cha: '',
    };
  },
  props: [
    'userid',
    'startday',
    'minute',
  ],
  components: {

  },
  created() {
      this.getMidCompute2();
  },

  mounted() {

    this.getMidCompute2();
    this.getweeks();

  },
  methods: {

    getweeks() {
      const startDate = new Date(this.startday);
      const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六']
      if (!isNaN(startDate.getTime())) {
        // 初始化一个数组，用于存储一星期内每天的星期值
        // 循环计算一星期内每天的星期值
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + i);
          this.nextWeekDays.push(daysOfWeek[currentDate.getDay()]);
        }
      } else {
        this.nextWeekDays = ['无效日期'];
      }
      // console.log(this.nextWeekDays)
    },
    ADD() {
      this.processArray2();
      this.processArray();

      this.drawGraph(this.generatedArray288, this.generatedArray70panduan, this.GM, this.GM2, this.generatedArray110, this.generatedArray180, this.generatedArray70cha,
          this.generatedArray2881, this.generatedArray70panduan1, this.generatedArray1101, this.generatedArray1801, this.generatedArray70cha1, this.GMcha, this.GM2cha,
          this.GM_real,this.GM2_real)
      console.log("GMCHA:",this.GMcha)
      console.log("GM2CHA:",this.GM2)

    },

    getMidCompute2() {
      axios.get('http://js.aps.ink:8090/selectbg_realFromOneDay', {
        params: {
          id: this.userid,
          startday: this.startday,
        }
      }).then(res => {
        if (res.data.status === 400) {
          // 登录失败提示
          console.error('登录失败');
        } else {
          // 登录成功后进行处理
          this.GM1 = res.data.mgList;
          this.starttime = res.data.timeList[0];
          const hour = parseInt(this.starttime.substring(11, 13), 10);
          const fen = parseInt(this.starttime.substring(14, 16), 10);
          const move = Math.round((hour * 60 + fen) / 5);

          this.cha = this.date1 - move;

          // 填充 GM 数组前面的空白部分
          for (let i = 0; i < move; i++) {
            this.GM[i] = 0;
          }

          // 将 GM1 数据复制到 GM 数组
          for (let i = move; i < this.date1; i++) {
            this.GM[i] = this.GM1[i - move];
          }

          // 处理 GM2 数组
          const index = this.date1 - move;
          this.GM2 = this.GM1.slice(index);
          for (let i = this.GM2.length; i < this.date2; i++) {
            this.GM2[i] = 70; // 填充默认值
          }

          // 处理 GM 和 GM2 中的数据，转换为负值的绝对值
          this.GM_real = this.GM.map(value => (value > 0 ? 0 : Math.abs(value)));
          this.GM2_real = this.GM2.map(value => (value > 0 ? 0 : Math.abs(value)));

          // 将负值转为 0
          this.GM = this.GM.map(value => (value < 0 ? 0 : value));
          this.GM2 = this.GM2.map(value => (value < 0 ? 0 : value));

          // 填充 0 区域，跳过第一个非零数前的填充
          this.GM = this.fillZeroWithNeighborData(this.GM);
          this.GM2 = this.fillZeroWithNeighborData(this.GM2);
          // 插值操作
          this.GMcha = this.interpolateZeros(this.GM, 6);
          this.GM2cha = this.interpolateZeros(this.GM2, 6);
          this.GM = this.fillZeroWithnullData(this.GM);
          this.GM2 = this.fillZeroWithnullData(this.GM2);
          // 调用 ADD 方法
          this.ADD();
        }
      }).catch(error => {
        console.log('请求失败:', error);
      });
    },
    fillZeroWithnullData(data) {
      // 1. 找到最后一个非零数据的索引
      let lastNonZeroIndex = -1;
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== 0 && !isNaN(data[i])) {
          lastNonZeroIndex = i;  // 更新最后一个非零数据的索引
        }
      }

      // 2. 根据最后一个非零数据的位置进行处理
      return data.map((value, index, array) => {
        // 头部和尾部直接返回原值
        if (index === 0 || index === array.length - 1) {
          return value;
        }

        // 如果是最后一个非零数据后的位置且为 NaN，则不做处理
        if (index > lastNonZeroIndex && isNaN(value)) {
          return value;  // 保持 NaN 不变
        }

        // 如果是中间的 NaN，替换为 0
        if (isNaN(value)) {
          return 0;
        }

        return value; // 保留非 NaN 的值
      });
    }
    ,
// 填充 0 区域，使用邻近有效值填充
    fillZeroWithNeighborData(arr) {
      let newArr = [...arr]; // 创建副本，避免修改原始数组

      // 找到第一个非零的值的索引
      let firstNonZeroIndex = -1;
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i] !== 0) {
          firstNonZeroIndex = i;
          break;
        }
      }

      // 从第一个非零数之前的位置开始填充（即保证第一个非零值之前的 0 不被填充）
      for (let i = firstNonZeroIndex - 1; i >= 0; i--) {
        if (newArr[i] === 0) {
          // 如果是0，且是第一个非零数之前的0，跳过填充
          continue;
        }
      }

      // 从第一个非零数的位置开始处理 0，进行填充
      for (let i = firstNonZeroIndex; i < newArr.length; i++) {
        // 如果当前值为 0 且不是原始 0（即负值转为 0）
        if (newArr[i] === 0) {
          // 找到下一个有效值，优先使用右侧的值
          let right = i + 1;
          while (right < newArr.length && newArr[right] === 0) {
            right++;
          }
          if (right < newArr.length) {
            newArr[i] = newArr[right]; // 用右侧的有效值填充当前的 0
          } else {
            // 如果右侧没有有效值，尝试用左侧有效值填充
            let left = i - 1;
            while (left >= 0 && newArr[left] === 0) {
              left--;
            }
            if (left >= 0) {
              newArr[i] = newArr[left]; // 用左侧的有效值填充当前的 0
            }
          }
        }
      }

      return newArr;
    }
    ,

    // 对连续的等于 0 的点进行插值
  interpolateZeros(arr, threshold) {
    let zeroStart = -1; // 连续0的起始位置
    let zeroCount = 0;  // 连续0的计数
    let resultArray = new Array(arr.length).fill(0); // 创建新数组，初始为全0

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 0|| isNaN(arr[i])) {
        if (zeroStart === -1) zeroStart = i; // 开始记录连续0的起始位置
        zeroCount++;
      } else {
        if (zeroCount > 0 && zeroCount <= threshold) {
          // 插值操作
          const startValue = arr[zeroStart - 1] || 0; // 前一个有效值
          const endValue = arr[i]; // 后一个有效值

          // 线性插值计算
          for (let j = 0; j < zeroCount; j++) {
            arr[zeroStart + j] = startValue + (endValue - startValue) * ((j + 1) / (zeroCount + 1));
            resultArray[zeroStart + j] = 35; // 固定插值为35
          }
        }
        zeroStart = -1; // 重置起始位置
        zeroCount = 0;  // 重置计数
      }
    }

    // 如果数组末尾有连续0
    if (zeroCount > 0 && zeroCount <= threshold) {
      const startValue = arr[zeroStart - 1] || 0; // 前一个有效值
      const endValue = arr[arr.length - 1] || 0; // 假设最后一个有效值为0
      for (let j = 0; j < zeroCount; j++) {
        arr[zeroStart + j] = startValue + (endValue - startValue) * ((j + 1) / (zeroCount + 1));
        resultArray[zeroStart + j] = 35; // 固定插值为35
      }
    }

    return resultArray;
  },
    //96//

    processArray2() {
      for (let i = 0; i < this.date1; i++) {
        if (this.GM2[i] === 0) {
          this.generatedArray1101[i] = 180;
        }
        else {
          this.generatedArray1101[i] = 110;
        }

      }

      for (let i = 0; i < this.date1; i++) {
        this.generatedArray2881[i] = "";

      }
      ;
      for (let i = 0; i < this.date1; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70panduan1[i] = 70
        } else {
          this.generatedArray70panduan1[i] = this.GM2[i]

        }
      }
      ;
      for (let i = 0; i < this.date1; i++) {
        if (this.GM2[i] < 180) {
          this.generatedArray1801[i] = 0
        } else {
          this.generatedArray1801[i] = this.GM2[i] - 180

        }
      }
      ;
      for (let i = 0; i < this.date1; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70cha1[i] = 0
        } else if(this.GM2[i] === 0){
          this.anomalyArray1[i] = 70
        }
        else{
          this.generatedArray70cha1[i] = 70 - this.GM2[i]
        }
      }


    },


    processArray() {
      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] === 0) {
          this.generatedArray110[i] = 180;
        }
        else {
          this.generatedArray110[i] = 110;
        }

      }

      for (let i = 0; i < this.date1; i++) {
        this.generatedArray288[i] = "";

      }

      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70panduan[i] = 70
        } else {
          this.generatedArray70panduan[i] = this.GM[i]

        }
      }

      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] < 180) {
          this.generatedArray180[i] = 0
        } else {
          this.generatedArray180[i] = this.GM[i] - 180

        }
      }

      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70cha[i] = 0
        } else if(this.GM[i] === 0){
          this.anomalyArray[i] = 70
        }
        else {
          this.generatedArray70cha[i] = 70 - this.GM[i]
        }
      }


    },


    drawGraph(generatedArray288, generatedArray70panduan, GM, GM2, generatedArray110, generatedArray180, generatedArray70cha,
              generatedArray2881, generatedArray70panduan1, generatedArray1101, generatedArray1801, generatedArray70cha1,GMcha,GM2cha,
              GM_real,GM2_real
    ) {
      const myChart = echarts.init(document.getElementById('myChart2'));

      const option = {

        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        toolbox: {
          feature: {}
        },
        grid: [
          {
            left: '3%',
            right: '4%',
            top: '3%',
            bottom: '50%',  // 调整第一个网格的 bottom 属性
            containLabel: true
          },
          {
            left: '3%',
            right: '4%',
            top: '50%',  // 调整第二个网格的 top 属性
            bottom: '3%',  // 调整第二个网格的 bottom 属性
            containLabel: true
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray288,
            gridIndex: 0,
            zlevel: 1, // 提高此轴及其相关元素的图层等级
            axisLabel: {
              interval: Math.ceil(generatedArray288.length / 14) - 1,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#212020',
                width: 1,
              },
              interval: Math.floor(generatedArray288.length / 7),
            }
          },
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray2881,
            gridIndex: 1,
            zlevel: 1, // 同样提高第二个轴的图层等级
            axisLabel: {
              interval: Math.ceil(generatedArray2881.length / 14) - 1,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#212020',
                width: 1,
              },
              interval: Math.floor(generatedArray2881.length / 7),
            }
          }
        ],
        yAxis: [
          {
            name: "毫克/分钟",
            type: 'value',
            gridIndex: 0
          },
          {
            type: 'value',
            gridIndex: 1
          }
        ],
        series: [
          {
            type: 'scatter', // 使用散点图类型
            symbolSize:5,
            itemStyle: {
              color: 'rgb(0,13,252)', // 红色圆圈
            },
            z:100,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0,// 指定使用第二个y轴
            data: GMcha.map(value => value === 0 ? null : value), // 散点图数据
          },
          // 校准值：将值为0的地方替换为null
          {
            type: 'scatter', // 使用散点图类型
            symbolSize: 10, // 可以调整星形的大小
            symbol: 'path://M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z', // 星形路径数据
            itemStyle: {
              color: 'rgb(255,0,0)', // 星形的颜色
            },
            z: 100,
            xAxisIndex: 0, // 指定使用第一个x轴
            yAxisIndex: 0, // 指定使用第一个y轴
            data: GM_real.map(value => value === 0 ? null : value), // 将值为0的地方替换为 null，其他值保持
          },
          {
            type: 'scatter', // 使用散点图类型
            symbolSize: 10, // 可以调整星形的大小
            symbol: 'path://M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z', // 星形路径数据
            itemStyle: {
              color: 'rgb(255,0,0)', // 蓝色星形
            },
            z: 100,
            xAxisIndex: 1, // 指定使用第一个x轴
            yAxisIndex: 1, // 指定使用第一个y轴
            data: GM2_real.map(value => value === 0 ? null : value), // 处理第二组数据
          },



          {
            type: 'scatter', // 使用散点图类型
            symbolSize:5,
            itemStyle: {
              color: 'rgb(0,13,252)', // 红色圆圈
            },
            z:100,
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1,// 指定使用第二个y轴
            data: GM2cha.map(value => value === 0 ? null : value), // 散点图数据
          },
            {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: 'rgb(208,243,199)' // 设置边界线的颜色为透明
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'//低血糖
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴

          },

          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)', // 背景颜色
              origin: 70 // 设置背景区域的起始位置为70
            },
            lineStyle: {
              color: 'rgb(208,243,199)', // 设置边界线的颜色，例如绿色
              width: 2               // 设置边界线的宽度
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray110,
            xAxisIndex: 0, // 指定使用第一个 x 轴
            yAxisIndex: 0, // 指定使用第一个 y 轴
            z: 0 // 设置图层的 z-index，确保背景在所有图形之下
          },


          {
            type: 'line',
            smooth: true,
            stack: 'Total',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'//高血糖
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray180.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM.map(value => value !== 0 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: 'none',           // 无标记
              label: { show: false },   // 不显示标记线的标签
              lineStyle: {
                color: 'rgb(208,0,255)',         // 线条颜色为紫色
                width: 5,             // 线条宽度
                type: 'solid'         // 线条类型为实线
              },
              data: (() => {
                const acc = [];
                let start = null;
                let lastNonZeroIndex = -1; // 记录最后一个非零数据的索引

                GM.forEach((value, index) => {
                  // 如果值不为0，更新最后一个非零数据的索引
                  if (value !== 0) {
                    lastNonZeroIndex = index;
                  }

                  // 如果值为0且start为空，记录开始点
                  if (value === 0 && start === null) {
                    start = index;  // 记录开始点
                  } else if (value !== 0 && start !== null) {
                    // 只有当start不为null，且不是从第一个点开始为0时，才画上标记线
                    if (start !== 0) {
                      acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: index - 1, yAxis: 0 }]);
                    }
                    start = null;  // 重置开始点
                  }
                });

                // 如果最后的数据也是0，需要补充最后一段线，且不是从第一个点开始
                if (start !== null && start !== 0 && lastNonZeroIndex !== GM.length - 1) {
                  acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: GM.length - 1, yAxis: 0 }]);
                }

                return acc;
              })()
            },
            xAxisIndex: 0,  // 使用第一个 x 轴
            yAxisIndex: 0   // 使用第一个 y 轴
          },



          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan1.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1, // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: 'rgb(208,243,199)' // 设置边界线的颜色为透明
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'//2低血糖
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha1,
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1,// 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)',//2背景
              origin: 70
            },
            lineStyle: {
              color: 'rgb(208,243,199)', // 设置边界线的颜色，例如绿色
              width: 2               // 设置边界线的宽度
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1101.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            smooth: true,
            stack: 'Total1',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'//2高血糖
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1801.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM2.map(value => value !== 0 && value !==70 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: 'none',           // 无标记
              label: { show: false },   // 不显示标记线的标签
              lineStyle: {
                color: 'rgb(208,0,255)',         // 线条颜色为红色
                width: 5,             // 线条宽度
                type: 'solid'         // 线条类型为实线
              },
              data: (() => {
                const acc = [];
                let start = null;
                GM2.forEach((value, index) => {
                  if (value === 0 && start === null) {
                    start = index;  // 记录开始点
                  } else if (value !== 0 && start !== null) {
                    acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: index - 1, yAxis: 0 }]);
                    start = null;  // 重置开始点
                  }
                });
                // 如果最后的数据也是0，需要补充最后一段线
                if (start !== null) {
                  acc.push([{ xAxis: start, yAxis: 0 }, { xAxis: GM.length - 1, yAxis: 0 }]);
                }
                return acc;
              })()
            },
            xAxisIndex: 1,  // 使用第一个 x 轴
            yAxisIndex: 1   // 使用第一个 y 轴
          },


        ]
      }
      myChart.setOption(option);
    },


  }

}


</script>

<style scoped>
#myChart {
  width: 10%;
}

.xingqi1 {
  margin-left: 150px;
  margin-top: -200px;


}

.xingqi2 {
  margin-left: 350px;



}

.xingqi3 {
  margin-left: 550px;


}

.xingqi4 {
  margin-left: 750px;


}

.xingqi5 {
  margin-left: 950px;


}

.xingqi6 {
  margin-left: 1150px;


}

.xingqi7 {
  margin-left: 1350px;


}



.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #ffffff;
  }
  .bg-purple {
    background: transparent;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
